import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import PostLink from '../components/post-link';
import NavBar from '../components/nav';
import { Link } from 'gatsby';
import Footer from '../components/Footer';
import Seo from '../components/Seo';

export default function Template({
  data,
  data: {
    allMarkdownRemark: { edges },
  },
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;

  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)
    .slice(0, 6); // Decide how many to display

    const title = frontmatter.title.split(' ').splice(0, 7).join(' ')
    const desc = data.markdownRemark.html.substr(0, 120)

  return (
    <Layout>
      <Seo 
        title={title}
        description={desc}
      />      
      <header>
        <NavBar
          navClass={'bg-white'}
          logo={'/assets/main-logo-blue.svg'}
          class={'text-darkblue border-darkblue'}
        />
      </header>
      <main>
        <section className="w-full mt-36 sm:mt-44 pb-20">
          <div className="container max-w-screen-xl mx-auto px-10 md:px-20 lg:px-32 xl:px-48">
            <article className="blog-content space-y-16">
              <div className="space-y-10">
                <div className="space-y-1">
                  <div className="flex justify-between">
                    <span className="italic font-poppins text-darkblue pl-1 text-xs md:text-sm xl:text-lg font-light">
                      {' '}
                      Ideas{' '}
                    </span>
                    <Link
                      to="/"
                      className="hidden sm:flex items-center cursor-pointer hover:underline"
                    >
                      <span className="italic font-poppins text-xs pr-3 font-light">
                        {' '}
                        Back to Home{' '}
                      </span>
                      <svg
                        className="h-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="11.794"
                        height="11.428"
                        viewBox="0 0 11.794 11.428"
                      >
                        <path
                          id="Polygon_15"
                          data-name="Polygon 15"
                          d="M4.5,0,9,8H0Z"
                          transform="translate(7.794 11.428) rotate(-150)"
                          fill="#1e3d49"
                        />
                      </svg>
                    </Link>
                  </div>
                  <h1 className="text-2xl font-arima text-darkblue sm:w-3/4 md:text-3xl xl:text-4xl">
                    {frontmatter.title}
                  </h1>
                </div>

                <img
                  className="w-full object-cover"
                  src={frontmatter.thumbnail}
                  alt={frontmatter.title}
                />
              </div>
              <div
                id="blog-content"
                className="font-poppins text-darkblue"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </article>
          </div>
        </section>
        <section
          className="w-full bg-lightpink  text-darkblue bg-contain bg-no-repeat bg-right"
          style={{ backgroundImage: `url(${'/assets/industry-bg.svg'})` }}
        >
          <div className="container max-w-screen-xl pt-20 pb-10 px-10 md:px-20 lg:px-24 xl:px-40 space-y-6">
            <div className="w-full border-b border-darkblue sm:flex justify-between items-center pb-3">
              <div>
                <h2 className="font-poppins italic text-lg md:text-xl font-light">
                  {' '}
                  Ideas{' '}
                </h2>
              </div>
              <div className="hidden sm:block">
                <Link
                  className="flex items-center hover:underline"
                  to={'/ideas'}
                >
                  <span className="text-xs font-poppins italic pr-3">
                    See all of our ideas
                  </span>
                  <svg
                    className="h-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.794"
                    height="11.428"
                    viewBox="0 0 11.794 11.428"
                  >
                    <path
                      id="Polygon_30"
                      data-name="Polygon 30"
                      d="M4.5,0,9,8H0Z"
                      transform="translate(7.794 11.428) rotate(-150)"
                      fill="#1e3d49"
                    />
                  </svg>
                </Link>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {Posts}
            </div>
            <div className="flex items-center justify-center pt-6 sm:hidden">
                <Link
                  className="flex items-center hover:underline"
                  to={'/ideas'}
                >
                  <span className="text-xs font-poppins italic pr-3">
                    See all of our ideas
                  </span>
                  <svg
                    className="h-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.794"
                    height="11.428"
                    viewBox="0 0 11.794 11.428"
                  >
                    <path
                      id="Polygon_30"
                      data-name="Polygon 30"
                      d="M4.5,0,9,8H0Z"
                      transform="translate(7.794 11.428) rotate(-150)"
                      fill="#1e3d49"
                    />
                  </svg>
                </Link>
              </div>
          </div>
        </section>
      </main>
      <Footer />
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        thumbnail
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
          }
        }
      }
    }
  }
`;
